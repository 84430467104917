import { userLogged } from 'utils/essentialData';
import * as Yup from 'yup';

const loggedUser = userLogged() ?? undefined;

const validations = [
  Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    birthdate: Yup.date().required('A data de nascimento é obrigatória'),
    school: Yup.string().required('A escola é obrigatória'),
    schooling: Yup.string(),
    school_term: Yup.string().required('O período é obrigatório'),
    father_name: Yup.string().required('O nome do pai é obrigatório'),
    father_age: Yup.number(),
    father_job: Yup.string(),
    mother_name: Yup.string().required('O nome da mãe é obrigatória'),
    mother_age: Yup.number(),
    mother_job: Yup.string(),
    brothers: Yup.string(),
    cep: Yup.string().required('O cep é obrigatório'),
    address: Yup.string().required('O logradouro é obrigatório'),
    number: Yup.number().required('O número é obrigatório'),
    neighborhood: Yup.string().required('O bairro é obrigatório'),
    city: Yup.string().required('A cidade é obrigatória'),
    state: Yup.string().required('O estado é obrigatório'),
    forwarded_by: Yup.string(),
    accompanying_doctor: Yup.string().required('O médico é obrigatório'),
    supervisor_uuid: Yup.object()
      .required('O supervisor é obrigatório')
      .nullable(),
    // therapist_uuids: Yup.array()
    //   .required('O terapeuta é obrigatório')
    //   .min(1, 'Selecione ao menos 1 terapeuta')
    //   .nullable(),
  }),
];

export const validationsEdit = [
  Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    birthdate: Yup.date().required('A data de nascimento é obrigatória'),
    school: Yup.string().required('A escola é obrigatória'),
    schooling: Yup.string(),
    school_term: Yup.string().required('O período é obrigatório'),
    father_name: Yup.string().required('O nome do pai é obrigatório'),
    father_age: Yup.number(),
    father_job: Yup.string(),
    mother_name: Yup.string().required('O nome da mãe é obrigatória'),
    mother_age: Yup.number(),
    mother_job: Yup.string(),
    // brothers: Yup.string().required('O campo irmãos é obrigatório'),
    cep: Yup.string().required('O cep é obrigatório'),
    address: Yup.string().required('O logradouro é obrigatório'),
    number: Yup.number().required('O número é obrigatório'),
    neighborhood: Yup.string().required('O bairro é obrigatório'),
    city: Yup.string().required('A cidade é obrigatória'),
    state: Yup.string().required('O estado é obrigatório'),
    forwarded_by: Yup.string(),
    accompanying_doctor: Yup.string().required('O médico é obrigatório'),
    supervisor_uuid: Yup.object()
      .required('O supervisor é obrigatório')
      .nullable(),
    // therapist_uuids: Yup.array()
    //   .required('O terapeuta é obrigatório')
    //   .min(1, 'Selecione ao menos 1 terapeuta')
    //   .nullable(),
    status: Yup.number().required('O status é obrigatório').nullable(),
  }),
];

export const anamnesisValidations = [Yup.object().shape({})];

export const fileValidations = [
  Yup.object().shape({
    file: Yup.mixed().required('O arquivo é obrigatório'),
    // type: Yup.string().required("O tipo é obrigatório"),
  }),
];

export const meetingsValidations = [
  Yup.object().shape({
    participants: Yup.string().required('Os participantes são obrigatórios'),
    description: Yup.string()
      .required('A descrição é obrigatória')
      .test('description', 'A descrição é obrigatória', (value, options) => {
        if (value && value === '<p><br></p>') return false;
        return true;
      }),
    // patient_uuid: Yup.string().required("O paciente é obrigatório"),
    // school: Yup.string().required("A escola é obrigatória"),
    type: Yup.string().required('O tipo é obrigatório').nullable(),
    date: Yup.date().required('A data é obrigatória'),
    current_therapist: Yup.string().required('O terapeuta é obrigatório'),
  }),
];

export const programsValidations = [
  Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    // application_phase: Yup.string().required(
    //   'A fase de aplicação é obrigatória'
    // ),
    // status: Yup.string().required('O nome é obrigatório'),
    procedure: Yup.string().required('O procedimento é obrigatório'),
    criterion: Yup.string().required('O critério é obrigatório'),

    supervisor_uuid:
      loggedUser?.role == 99
        ? Yup.object().required('O supervisor é obrigatório').nullable()
        : Yup.object().nullable(),
    attemps_number: Yup.number()
      .positive('A tentativa deve ser positiva')
      .min(1, 'Número mínimo é 1')
      .max(100, 'Número máximo é 100')
      .required('As quantidade de tentativas é obrigatória'),
    objectives: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
        })
      )
      .required('O Objetivo é obrigatório')
      .min(1, 'Um campo é obrigatorio'),
  }),
];

export const programsValidationsEdit = [
  Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    // application_phase: Yup.string().required(
    //   'A fase de aplicação é obrigatória'
    // ),
    status: Yup.string().required('O status é obrigatório'),
    procedure: Yup.string().required('O procedimento é obrigatório'),
    criterion: Yup.string().required('O critério é obrigatório'),
    supervisor_uuid:
      loggedUser?.role == 99
        ? Yup.object().required('O supervisor é obrigatório').nullable()
        : Yup.object().nullable(),
    attemps_number: Yup.number()
      .positive('A tentativa deve ser positiva')
      .min(1, 'Número mínimo é 1')
      .max(100, 'Número máximo é 100')
      .required('As quantidade de tentativas é obrigatória'),
    objectives: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
        })
      )
      .required('O Objetivo é obrigatório')
      .min(1, 'Um campo é obrigatorio'),
  }),
];

export const behaviorsValidations = [
  Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    // application_phase: Yup.string().required(
    //   'A fase de aplicação é obrigatória'
    // ),
    status: Yup.number().required('O status é obrigatório').nullable(),
    supervisor_uuid:
      loggedUser?.role == 99
        ? Yup.object().required('O supervisor é obrigatório').nullable()
        : Yup.object().nullable(),
    register_type: Yup.string().required('O campo é obrigatório'),
  }),
];

export const behaviorsValidationsEdit = [
  Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    // application_phase: Yup.string().required(
    //   'A fase de aplicação é obrigatória'
    // ),
    status: Yup.string().required('O status é obrigatório'),
    supervisor_uuid:
      loggedUser?.role == 99
        ? Yup.object().required('O supervisor é obrigatório').nullable()
        : Yup.object().nullable(),
    register_type: Yup.string().required('O campo é obrigatório'),
  }),
];

export default validations;
