/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import toastError from 'shared/components/snackbar/error/toastError';
import { Card, Grid, Icon, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import DataTableApi from 'shared/components/tables/DatatableApi';

import MDInput from 'shared/components/MDInput';
import MDButton from 'shared/components/MDButton';
import PopUp, { dialogButton } from 'shared/components/pop_up';
import { useParams } from 'react-router-dom';
import { formatDateFromApi } from 'utils/essentialData';
import MDProgress from 'shared/components/MDProgress';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';
import localFilePatientsService from 'shared/services/local_file_patients/local_file_patients.service';

function FileList({ setFileStep }) {
  const { uuid } = useParams();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [filter, setFilter] = useState('');
  const [filterSearch, setFilterSearch] = useState('');

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const handleDelete = (row) => (
    <IconButton
      title='Deletar'
      onClick={() => {
        setOpenPopUp(true);
        setRowSelected(row);
      }}
    >
      <Icon color='error'>delete</Icon>
    </IconButton>
  );

  const downloadFile = async (row) => {
    try {
      await localFilePatientsService.getFile(row);
    } catch (e) {
      // toastError(e.message);
    }
  };

  const buttonDownloadFile = (row) => (
    <IconButton
      title='download'
      onClick={() => {
        downloadFile(row.original.uuid);
      }}
    >
      <Icon color='primary'>file_download</Icon>
    </IconButton>
  );

  const dataTableData = {
    columns: [
      {
        Header: 'Nome do arquivo',
        accessor: 'name',
      },
      {
        Header: 'Data do arquivo',
        accessor: 'created_at',
        Cell: ({ value }) => formatDateFromApi(value),
        // width: "15%",
      },
      {
        Header: 'Link do arquivos',
        accessor: 'link',
        Cell: ({ cell: { row } }) => buttonDownloadFile(row),
        width: '15%',
      },
      // {
      //   Header: "Tipo de arquivos",
      //   accessor: "type",
      //   Cell: ({ value }) => translateFilePatientsOptions(value),
      //   width: "15%",
      // },
      {
        Header: 'Excluir',
        accessor: 'delete',
        Cell: ({ cell: { row } }) => handleDelete(row),
        width: '15%',
      },
    ],

    rows: dataTable?.data || [],
  };

  const handleCancelDelete = () => setOpenPopUp(false);

  const handleApplyDelete = async () => {
    try {
      await localFilePatientsService.delete(rowSelected.original.uuid);
      toastSuccess('Arquivo apagado com sucesso');
      loadFilePatients(tablePage, tableLimit, filterSearch);
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenPopUp(false);
    }
  };

  const loadFilePatients = async (page = 1, limit = 5, filter = '') => {
    try {
      setLoadingData(true);
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await localFilePatientsService.list(
        `/patient/${uuid}?page=${page}&limit=${limit}${filterValue}`,
        setLoadingData
      );
      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    if (value === '') {
      loadFilePatients(1, tableLimit);
      setTablePage(1);
      setFilterSearch('');
    }
    setFilter(value);
  };

  const handleClickSearch = () => {
    setFilterSearch(filter.trim());
    setTablePage(1);
    loadFilePatients(1, tableLimit, filter);
  };

  useEffect(
    () => loadFilePatients(tablePage, tableLimit, filterSearch),
    [tablePage, tableLimit]
  );

  return (
    <>
      <Card sx={{ marginBottom: 1 }}>
        <MDBox p={3} lineHeight={1}>
          <MDTypography variant='h5' fontWeight='medium'>
            Filtros
          </MDTypography>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <MDInput
                type='text'
                name='filter'
                value={filter}
                size='small'
                fullWidth
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ visibility: filter ? 'visible' : 'hidden' }}
                      size='small'
                      onClick={() => handleChange({ target: { value: '' } })}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2} display='flex'>
              <MDButton
                disabled={!filter}
                variant='gradient'
                color='primary'
                size='small'
                fullWidth
                onClick={handleClickSearch}
              >
                BUSCAR
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
      <Card>
        <MDBox p={3} lineHeight={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2}>
              <MDButton
                variant='gradient'
                color='primary'
                size='small'
                fullWidth
                onClick={() => setFileStep('create')}
              >
                UPLOAD
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
        {loadingData ? (
          <MDProgress
            variant='gradient'
            variantProgress='indeterminate'
            color='primary'
          />
        ) : (
          <DataTableApi
            table={dataTableData}
            onRowSelected={setRowSelected}
            entriesPerPage={{ defaultValue: tableLimit }}
            setTableLimit={setTableLimit}
            tableLimit={tableLimit}
            setTablePage={setTablePage}
            tablePage={tablePage}
            tableTotal={tableTotal}
          ></DataTableApi>
        )}
      </Card>
      <PopUp
        open={openPopUp}
        onClose={() => setRowSelected()}
        title='Apagar arquivo'
        contentText='Deseja apagar permanentemente este arquivo?'
        actions={[
          <MDButton
            color='dark'
            onClick={handleCancelDelete}
            style={dialogButton}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton
            color='error'
            onClick={handleApplyDelete}
            style={dialogButton}
            key='confirm'
          >
            apagar
          </MDButton>,
        ]}
      />
    </>
  );
}

FileList.defaultProps = {
  setFileStep: () => {},
};

FileList.propTypes = {
  setFileStep: PropTypes.func,
};

export default FileList;
